(function ($) {
  Drupal.behaviors.faqFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-faq-formatter-v1', context);
      var $trigger = $('.js-faq-formatter__toggle', $template);

      // Check if this has a trigger
      if ($trigger.length) {
        // Toggle the nav
        $trigger.once().on('click', function () {
          var $this = $(this);
          var $parent = $this.parent();
          var $content = $parent.next('.js-faq-formatter__items');
          // Cast the expanded state as a boolean
          var expanded = $parent.attr('aria-expanded') === 'false' ? false : true;

          // Switch the states of aria-expanded and aria-hidden
          $parent.attr('aria-expanded', !expanded);
          $content.attr('aria-hidden', expanded);
        });
      }
    }
  };
})(jQuery);
